<template>
<!-- Add Syllabus Template Content Modal -->
<div class="modal fade" id="addSyllabusTemplateContentModal" tabindex="-1"
     aria-labelledby="addSyllabusTemplateContentModalLabel"
     aria-hidden="true">
<div class="modal-dialog modal-xl">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="addSyllabusTemplateContentModalLabel">Добавление</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div class="modal-body">

      <div class="col-md-12 mt-4">

        <div v-if="syllabusContent_form.syllabusEvaluationOptionId != 6">
          <div class="row mt-4" v-for="n in 15" :key="n">
            <label class="col-md-4 col-form-label">Неделя {{ n }}</label>
            <div class="col-md-6">
              <div v-if="n===2" class="mt-1">
                Согласно п. 6.2 <a href="https://back.uib.kz/common/syllabus_rules.pdf" target="_blank">Положения о проведении самостоятельных научных работ студентов (СНРС, ROS)</a>, лекция второй недели обучения должна содержать тему "Методы научных исследований"
              </div>
            <textarea class="form-control"
                      placeholder="Название темы"
                      @change="changeNewSyllabusContentData('week'+ n, $event)">
            </textarea>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="row mt-4" v-for="n in 10" :key="n">
            <label class="col-md-4 col-form-label">Неделя {{ n }}</label>
            <div class="col-md-6">
              <div v-if="n===2" class="mt-1">
                Согласно п. 6.2 <a href="https://back.uib.kz/common/syllabus_rules.pdf" target="_blank">Положения о проведении самостоятельных научных работ студентов (СНРС, ROS)</a>, лекция второй недели обучения должна содержать тему "Методы научных исследований"
              </div>
            <textarea class="form-control"
                      placeholder="Название темы"
                      @change="changeNewSyllabusContentData('week'+ n, $event)">
            </textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
              @click="submitSyllabusContent">
        Создать
      </button>
      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
        Закрыть
      </button>
    </div>
  </div>
</div>
</div>
<!-- End Add Syllabus Template Content Modal -->
</template>


<script>
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
export default {
  name: "AddSyllabusTemplateContentModalOld",
  data() {
    return {
      syllabus_id: +this.$route.query.syllabus_id || 1,
    }
  },
  computed: {
    ...mapState('syllabusContentOld', ['syllabusContent_form']),
  },

  methods: {
    ...mapActions('syllabusContentOld', ['POST_SYLLABUS_CONTENT_DATA','GET_SYLLABUS_CONTENT_DATA']),
    ...mapMutations('syllabusContentOld', ['SET_NEW_SYLLABUS_CONTENT_DATA']),
    changeNewSyllabusContentData(property, e, val = 'value') {
      const value = e.target[val]
      console.log(value, "value")
      this.SET_NEW_SYLLABUS_CONTENT_DATA({property, value})
    },
    submitSyllabusContent() {
      this.POST_SYLLABUS_CONTENT_DATA(this.syllabus_id).then(res => {
        if (res.success == true) {
          this.GET_SYLLABUS_CONTENT_DATA(this.syllabus_id)
          this.$message({title: 'Добавление', text: 'Данные успешно добавились'})
        } else {
          let errorText = '';
          for (let err of res.errors[0]) {
            errorText += err.message + '\n'
          }
          this.$error({title: 'Ошибка при добавлении', text: errorText})
        }
      })
    },

  },
  async mounted() {
  }
}
</script>

<style scoped>

</style>
