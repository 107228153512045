<template>
  <div class="container">


    <div class="syllabus-template-content mt-4">
      <h4 class="text-center">Содержание дисциплины</h4>

      <AddSyllabusTemplateContentModalOld/>

      <div class="row mb-4" v-if="syllabusContent_form.syllabusContentInfos == null">
        <div class="col-md-12">
          <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                  data-bs-target="#addSyllabusTemplateContentModal" @click="openAddSyllabusContentModal">
            <i class="fas fa-plus-circle" aria-hidden="true"></i>&nbsp;Добавить содержание
          </button>
        </div>
      </div>
      <div v-else>
        <div v-if="syllabusContent_form.syllabusEvaluationOptionId != 6">
          <div class="row mt-4" v-for="n in 15" :key="n">
            <label class="col-md-4 col-form-label">Неделя {{ n }}</label>
            <div class="col-md-6">
              <div v-if="n===2" class="mt-1">
                Согласно п. 6.2 <a href="https://back.uib.kz/common/syllabus_rules.pdf" target="_blank">Положения о проведении самостоятельных научных работ студентов (СНРС, ROS)</a>, лекция второй недели обучения должна содержать тему "Методы научных исследований"
              </div>
              <!--          <input v-if="syllabusContent_form.syllabusContentInfos == null" type="text" class="form-control"-->
              <!--                 placeholder="Название темы"-->
              <!--                 @change="changeNewSyllabusContentData('week '+ n, $event)">-->

              <textarea class="form-control"
                        placeholder="Название темы"
                        :value="syllabusContent_form?.syllabusContentInfos['week'+(n)]"
                        @change="changeSyllabusContentData('week'+ n, $event)">
            </textarea>
            </div>

          </div>

          <div class="col-md-12 mt-4 mb-4 text-center" style="min-height: 120px">
            <button type="button" class="btn btn-primary" @click="updateSyllabusContent">Сохранить
            </button>
          </div>
        </div>

        <div v-else>
          <div class="row mt-4" v-for="n in 10" :key="n">
            <label class="col-md-4 col-form-label">Неделя {{ n }}</label>
            <div class="col-md-6">
              <div v-if="n===2" class="mt-1">
                Согласно п. 6.2 <a href="https://back.uib.kz/common/syllabus_rules.pdf" target="_blank">Положения о проведении самостоятельных научных работ студентов (СНРС, ROS)</a>, лекция второй недели обучения должна содержать тему "Методы научных исследований"
              </div>
              <!--          <input v-if="syllabusContent_form.syllabusContentInfos == null" type="text" class="form-control"-->
              <!--                 placeholder="Название темы"-->
              <!--                 @change="changeNewSyllabusContentData('week '+ n, $event)">-->

              <textarea class="form-control"
                        placeholder="Название темы"
                        :value="syllabusContent_form?.syllabusContentInfos['week'+(n)]"
                        @change="changeSyllabusContentData('week'+ n, $event)">
            </textarea>
            </div>

          </div>

          <div class="col-md-12 mt-4 mb-4 text-center" style="min-height: 120px">
            <button type="button" class="btn btn-primary" @click="updateSyllabusContent">Сохранить
            </button>
          </div>
        </div>
      </div>




<!--      <div class="row mt-4">-->
<!--        <div class="col-md-12">-->
<!--          <Accordion :activeIndex="0">-->
<!--            <AccordionTab>-->
<!--              <template #header>-->
<!--                <i class="pi pi-calendar"></i>-->
<!--                <span>Недели</span>-->
<!--              </template>-->
<!--            </AccordionTab>-->
<!--          </Accordion>-->
<!--        </div>-->
<!--      </div>-->



    </div>


  </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
import AddSyllabusTemplateContentModalOld from "@/components/syllabus/AddSyllabusTemplateContentModalOld.vue";

export default {
  name: "SyllabusTemplateContentOld",
  components: {
    AddSyllabusTemplateContentModalOld
  },
  data() {
    return {
      syllabus_id: +this.$route.query.syllabus_id || 1,
    }
  },
  computed: {
    ...mapState('syllabusContentOld', ['syllabusContent_form']),
  },

  methods: {
    ...mapActions('syllabusContentOld', ['GET_SYLLABUS_CONTENT_DATA','PUT_SYLLABUS_CONTENT_DATA', 'GET_SYLLABUS_DATA_BY_ID']),
    ...mapMutations('syllabusContentOld', ['UPDATE_SYLLABUS_CONTENT_DATA','SET_URL_SYLLABUS_ID']),

    changeSyllabusContentData(property, e, val = 'value') {
      const value = e.target[val]
      console.log(value, "value")
      this.UPDATE_SYLLABUS_CONTENT_DATA({property, value})
    },

    async updateSyllabusContent() {

      await this.PUT_SYLLABUS_CONTENT_DATA(this.syllabus_id).then(res => {
        if (res.success == true) {
          this.$message({title: 'Обновление', text: 'Данные успешно обновились'})
          this.GET_SYLLABUS_CONTENT_DATA(this.syllabus_id)
        } else {
          let errorText = '';
          for (let err of res.errors[0]) {
            errorText += err.message + '\n'
          }
          this.$error({title: 'Обновление', text: errorText})
        }
      })

    },
    openAddSyllabusContentModal() {
      this.SET_URL_SYLLABUS_ID(this.syllabus_id)
    }
  },
  mounted() {
    this.GET_SYLLABUS_CONTENT_DATA(this.syllabus_id)
    this.GET_SYLLABUS_DATA_BY_ID(this.syllabus_id)
  }

}
</script>

<style scoped>

</style>